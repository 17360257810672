.vytalExplanation {
  @apply flex-1 flex flex-col justify-center items-center;
  background-color: var(--layout-high-contast);
  background-image: url('../../images/png/Zigzag-green.png'),
    url('../../images/png/Circle-lylac.png'),
    url('../../images/png/Square-red-quarter.png');
  background-repeat: no-repeat;
  background-position:
    top -150px left -150px,
    left -150px bottom -150px,
    right -150px top -150px;
  background-size: 300px;
}

.initialScreenContainer {
  @apply m-4 flex flex-col justify-center items-center;
}

.stepsContainer {
  @apply flex flex-col items-start space-y-1;
  max-width: 350px;
}

.stepContainerRow {
  @apply flex justify-center items-center mb-4 space-x-4;
  display: none;
}

.explanationText {
  font-size: 16px;
  color: white;
}

.explanationNumber {
  font-size: 36px;
  color: white;
  font-family: 'PPFormulaCondensed';
}

.welcomeText {
  font-size: 80px !important;
  letter-spacing: 3px;
  margin-right: -14px;
  color: white;
  font-family: 'PPFormulaCondensed';
}

@media (min-height: 500px) {
  .stepContainerRow {
    display: flex;
  }
}

.qrContainer {
  @apply flex justify-end items-center pr-6 pb-6;
  position: absolute;
  width: 49%;
  bottom: 0;
}
